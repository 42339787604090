import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "v-dbok" }
const _hoisted_2 = { class: "v-dbok__wrapper" }
const _hoisted_3 = { class: "v-dbok__sections" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TheHeading, {
        class: "v-dbok__heading",
        level: "h1"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('DBOK_HEADING')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Breadcrumbs),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dbok, (section) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "v-dbok__section",
            key: section.name
          }, [
            _createVNode(_component_TheHeading, { class: "v-dbok__section-heading" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(section.name), 1)
              ]),
              _: 2
            }, 1024),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.items, (item) => {
              return (_openBlock(), _createBlock(_component_Card, {
                class: "v-dbok__card",
                key: item.books[0].id,
                title: item.title,
                buttons: item.books,
                actions: _ctx.cardActions,
                type: "dbok",
                "image-id": item.books[0].id,
                "has-button": "",
                description: item.description
              }, null, 8, ["title", "buttons", "actions", "image-id", "description"]))
            }), 128))
          ]))
        }), 128))
      ])
    ])
  ]))
}