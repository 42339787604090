
import { defineComponent, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'

import { useTenant, useBreadcrumbs } from '@/core'
import TheHeading from '@/components/TheHeading.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Card from '@/components/Card.vue'

export default defineComponent({
  name: 'Dbok',

  components: {
    TheHeading,
    Breadcrumbs,
    Card,
  },

  setup() {
    const { tenant } = useTenant()
    const { t } = useI18n()
    const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()

    clearBreadcrumbs()
    setBreadcrumbs([{ name: t('DBOK_HEADING') }])

    const dbok = computed(() => tenant.value.dbok)

    const cardActions = {
      type: 'dbok',
    }

    useHead({
      title: computed(() => `${t('DBOK_HEADING')} - ${tenant.value.name}`),
    })

    return {
      dbok,
      cardActions,
    }
  },
})
